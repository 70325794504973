const firebaseConfig = {
  apiKey: "AIzaSyBMu3YueOwLDMZT-esVOlTfv9PrX9_salY",
  authDomain: "hatstand-8ab5c.firebaseapp.com",
  databaseURL: "https://hatstand-8ab5c.firebaseio.com",
  projectId: "hatstand-8ab5c",
  storageBucket: "hatstand-8ab5c.appspot.com",
  messagingSenderId: "143399895783",
  appId: "1:143399895783:web:045d16b3bed6ba1fe49842",
  measurementId: "G-CJ9TNJCE3P"
};

export default firebaseConfig;