import React, { useState, useEffect } from 'react';
import firebase, { User } from 'firebase';

const UserContext = React.createContext<any | null>(null);

const UserProvider: React.FC = (props) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [requiresEmailVerification, setRequiresEmailVerification] = useState(false);
  const db = firebase.firestore();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async function (_user) {
      if (_user) {
        db.collection('profiles').doc(_user.uid).get().then((result) => {
          let profile = result.data();
          if (profile?.division) {
            db.collection('profiles').doc(_user.uid).update({ email: _user.email });
            setUser({ user: _user, profile: profile });
            setIsLoading(false);
          } else {
            console.log(profile);
            alert('There seems to be a problem with your account, please try going through the "Set me up" process again or contact rebekka.squire@allianz.com.au for help fixing this.');
            logout();
          }
        }).catch((error) => {
          console.log(error);
          logout();
        })

      } else {
        setUser(null);
        setIsLoading(false);
      }
    });
  }, []);

  const register = (email: string, password: string) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  const login = (email: string, password: string) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  const sendPasswordReset = (email: string) => {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  const logout = () => firebase.auth().signOut().then(() => {

  });

  return (
    <UserContext.Provider value={{ user, isLoading, setUser, login, register, logout, requiresEmailVerification, sendPasswordReset }} {...props} />
  )
}

const useUser = () => {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export { useUser };
export default UserProvider;
