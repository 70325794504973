import React from 'react';
import Lottie from 'react-lottie';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import loadingSpinner from '../animations/528-spinner-loading.json';

const rowStyle = {
  height: '100vh'
}

const Loading: React.FC = (props: { children?: any; spinner?: any; }) => {
  const { spinner } = props;

  let defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: spinner ? spinner : loadingSpinner,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center" style={rowStyle}>
            <IonCol size="12" className="ion-text-center">
              {props.children}
              <Lottie options={defaultOptions} height={120} width={120} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>

  )
}

export default Loading;