
import React, { Suspense } from 'react';
import Loading from './components/Loading';
import { IonApp } from '@ionic/react';
import { useUser } from './context/UserProvider';
import * as firebase from 'firebase/app';
import firebaseConfig from './firebase-exports';

import { FirestoreProvider } from 'react-firestore';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './App.scss';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const loadUnauthenticatedApp = () => import('./UnauthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(loadUnauthenticatedApp);

firebase.initializeApp(firebaseConfig);

const App: React.FC = () => {
  firebase.analytics();
  const { user, isLoading } = useUser();

  loadAuthenticatedApp();
  loadUnauthenticatedApp();

  const renderLoader = () => <Loading>Building a hatstand...</Loading>;

  if (isLoading) return renderLoader();
  // if (error) return error;
  return (
    <IonApp>
      <FirestoreProvider firebase={firebase}>
        <Suspense fallback={renderLoader()}>
          {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </Suspense>
      </FirestoreProvider>
    </IonApp>
  )
};

export default App;
